import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, toArray } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { EndpointsService } from "../endpoints/endpoints.service";
import { AdresseerbaarObjectSuggestion } from "../model/dto/AdresseerbaarObjectSuggestion";
import { AotResponse } from "../model/dto/AotResponse";
import { VerblijfsObject } from "../model/dto/VerblijfsObject";
import { WozGegevens } from "../model/dto/WozGegevens";
import { WozObject } from "../model/dto/WozObject";
import { AdresMapper } from "../model/mapper/AdresMapper";
import { AdresseerbaarObjectSuggestionMapper } from "../model/mapper/AdresseerbaarObjectSuggestionMapper";
import { PrefixPipe } from "../shared/utils/prefix.pipe";
import { StreetAddressPipe } from "../shared/utils/street-address.pipe";
import { Suggestion } from "@kadaster/generieke-geo-componenten-search";
import { chunkIdentifiers } from "../shared/utils/http-utils";
import { ComponentPortal } from "@angular/cdk/portal";
import { ErrorComponent } from "../shared/error/error.component";
import { Overlay } from "@angular/cdk/overlay";

interface WozSuggest {
    docs: WozObject[];
}
@Injectable({
    providedIn: "root",
})
export class WozService {

    private errorShown = false;

    constructor(
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        private prefixPipe: PrefixPipe,
        private streetAddressPipe: StreetAddressPipe,
        private overlay: Overlay
    ) {}

    byNummeraanduiding(nummeraanduidingId: string): Observable<WozGegevens> {
        const identificatie = this.prefixPipe.transform(nummeraanduidingId, 16, "0");
        const url = `${this.endpointsService.wozService}/wozwaarde/nummeraanduiding/${identificatie}`;

        return this.httpClient.get<WozGegevens>(url).pipe(
            catchError((err) => {
                this.handleErrorStates(err);
                return throwError(err);
            })
        );   
    }

    waardeByVbo(vbos: string[], minWaarde?: number, maxWaarde?: number): Observable<string[]> {
        let params = new HttpParams();
        if (minWaarde) {
            params = params.append("minWaarde", minWaarde.toString(10));
        }
        if (maxWaarde) {
            params = params.append("maxWaarde", maxWaarde.toString(10));
        }

        return from(chunkIdentifiers(vbos, environment.vboFilterLimit)).pipe(
            mergeMap((vs) => this._waardeByVbos(vs, params), 2),
            toArray(),
            map((v) => v.reduce((acc, vals) => [...acc, ...vals], []))
        );
    }

    private _waardeByVbos(vbos: string[], params: HttpParams): Observable<string[]> {
        return this.httpClient
            .post<AotResponse>(`${this.endpointsService.wozService}/suggest/filter`, vbos, { params })
            .pipe(
                map((suggest) => suggest.ids),
                catchError((err) => {
                    this.handleErrorStates(err);
                    return throwError(err);
                })
            );
    }

    byWozobjectnummer(wozobjectnummer: string): Observable<WozGegevens> {
        const url = `${this.endpointsService.wozService}/wozwaarde/wozobjectnummer/${wozobjectnummer}`;
        return this.httpClient.get<WozGegevens>(url).pipe(
            catchError((err) => {
                this.handleErrorStates(err);
                return throwError(err);
            })
        );
    }

    search(query: string): Observable<Suggestion[]> {
        return this.suggestByUrl(`${this.endpointsService.wozService}/suggest?q=${encodeURIComponent(query)}`);
    }

    searchByStreet(query: string): Observable<Suggestion[]> {
        return this.suggestByUrl(`${this.endpointsService.wozService}/suggest?straat=${encodeURIComponent(query)}`);
    }

    searchByPandId(pandid: string): Observable<AdresseerbaarObjectSuggestion[]> {
        return this.suggestAOTByUrl(`${this.endpointsService.wozService}/suggest?pandid=${encodeURIComponent(pandid)}`);
    }

    searchByKadastraalPerceel(
        kozgemeente: string,
        kozsectie: string,
        kozperceel: string
    ): Observable<AdresseerbaarObjectSuggestion[]> {
        const params: HttpParams = new HttpParams()
            .set("gemeente", encodeURIComponent(kozgemeente))
            .set("sectie", encodeURIComponent(kozsectie))
            .set("perceel", encodeURIComponent(kozperceel));
        const url = `${this.endpointsService.wozService}/suggest/kadastraal?`;
        return this.suggestPagedAOTByUrl(url, params, 0);
    }

    searchByAdresseerbaarObjectIds(adresseerbaarObjectIds: string[]): Observable<AdresseerbaarObjectSuggestion[]> {
        const url = `${this.endpointsService.wozService}/suggest?aotids=${this.encodeUriList(adresseerbaarObjectIds)}`;
        return this.httpClient.get<WozSuggest>(url).pipe(
            catchError(() => of({ docs: [] })),
            map((suggest) => suggest.docs),
            map((docs: WozObject[]) =>
                docs.map((doc) => AdresseerbaarObjectSuggestionMapper.fromWozObject(doc, "wozSuggest"))
            )
        );
    }

    searchByVerblijfsObjecten(verblijfsObjects: VerblijfsObject[]): Observable<AdresseerbaarObjectSuggestion[]> {
        const chunks = [];
        const source = verblijfsObjects.map((e) => e.identificatie);
        while (source[0]) {
            chunks.push(source.splice(0, environment.aotSuggestLimit));
        }

        return forkJoin(chunks.map((ids) => this.searchByAdresseerbaarObjectIds(ids))).pipe(
            map((vals: AdresseerbaarObjectSuggestion[][]) => vals.reduce((acc, val) => [...acc, ...val], [])),
            map((vals: AdresseerbaarObjectSuggestion[]) => {
                return [
                    ...vals,
                    ...verblijfsObjects
                        .map((woz) => AdresseerbaarObjectSuggestionMapper.fromVerblijfsObject(woz, "wozSuggest"))
                        .filter(
                            (sugg) =>
                                !vals.find(
                                    (val) =>
                                        val.adresseerbaarObjectId === sugg.adresseerbaarObjectId ||
                                        val.verbondenAdresseerbareObjecten.includes(sugg.adresseerbaarObjectId) ||
                                        val.ontleendeAdresseerbareObjecten.includes(sugg.adresseerbaarObjectId)
                                )
                        ),
                ];
            }),
            map((suggestions) => suggestions.sort(this.sortAdresseerbaarSuggest))
        );
    }

    private suggestAOTByUrl(url: string): Observable<AdresseerbaarObjectSuggestion[]> {
        return this.httpClient.get<WozSuggest>(url).pipe(
            map((result) =>
                result.docs
                    .map((wozObject) => AdresseerbaarObjectSuggestionMapper.fromWozObject(wozObject, "wozService"))
                    .sort(this.sortAdresseerbaarSuggest)
            ),
            catchError((err) => {
                this.handleErrorStates(err);
                return of([]);
            })
        );
    }
    
    private handleErrorStates(err: any) {
        if (err instanceof HttpErrorResponse) 
            if(err.status === 404) {
                return of(null);
            } else if (err.status === 429) { // Too many requests
            if (!this.errorShown) {
                this.errorShown = true;
                const overlayRef = this.overlay.create({
                    hasBackdrop: true,
                    backdropClass: "overlay",
                    panelClass: "modal",
                });
                const err = new ComponentPortal(ErrorComponent);
                const componentRef = overlayRef.attach(err);
                componentRef.instance.error = "Maximum aantal verzoeken is overschreden";

                componentRef.instance.close.subscribe(() => {
                    componentRef.destroy();
                    overlayRef.dispose();
                    this.errorShown = false;
                });
            }
        }
    }

    private suggestPagedAOTByUrl(
        url: string,
        params: HttpParams,
        page: number
    ): Observable<AdresseerbaarObjectSuggestion[]> {
        return this.httpClient
            .get<WozSuggest>(url, { params: params.set("page", page).set("size", environment.kozSearchLimit) })
            .pipe(
                map((result) =>
                    result.docs
                        .map((wozObject) => AdresseerbaarObjectSuggestionMapper.fromWozObject(wozObject, "wozService"))
                        .sort(this.sortAdresseerbaarSuggest)
                ),
                catchError(() => of([] as AdresseerbaarObjectSuggestion[])),
                switchMap((results) =>
                    results.length >= environment.kozSearchLimit && page < 40 // maximale hoeveelheid in resultaat, mogelijk nog meer
                        ? forkJoin([of(results), this.suggestPagedAOTByUrl(url, params, page + 1)]).pipe(
                              map((res) => res.reduce((acc, val) => [...acc, ...val], []))
                          )
                        : of(results)
                )
            );
    }

    private suggestByUrl(url: string): Observable<Suggestion[]> {
        return this.httpClient.get<WozSuggest>(url).pipe(
            map((result) =>
                result.docs.sort(this.sortWozSuggest).map((wozObject) => {
                    const weergavenaam = this.streetAddressPipe.transform(AdresMapper.fromWozObject(wozObject));
                    return {
                        id: wozObject.wozobjectnummer.toString(10),
                        weergavenaam: `${weergavenaam}, ${wozObject.postcode || ""} ${wozObject.woonplaatsnaam || ""}`,
                        type: wozObject.locatieomschrijving,
                        score: 1,
                        source: "wozService",
                    };
                })
            ),
            catchError((err) => {
                this.handleErrorStates(err);
                return of([]);
            })
        );
    }
    

    private encodeUriList(list: string[]): string {
        return list.map((v) => encodeURIComponent(v)).join(",");
    }

    private sortAdresseerbaarSuggest(a: AdresseerbaarObjectSuggestion, b: AdresseerbaarObjectSuggestion) {
        let toevoegenCompare: number = a.toevoeging?.localeCompare(b.toevoeging);

        if (a.toevoeging && b.toevoeging) {
            const toevNumA: number = parseInt(a.toevoeging, 10);
            const toevNumB: number = parseInt(b.toevoeging, 10);
            if (!isNaN(toevNumA) && !isNaN(toevNumB)) {
                toevoegenCompare = toevNumA - toevNumB;
            }
        }

        let huisnummerCompare = 0;
        if (a.huisnummer && b.huisnummer) {
            huisnummerCompare = parseInt(a.huisnummer) - parseInt(b.huisnummer);
        }

        return (
            a.woonplaats?.localeCompare(b.woonplaats) ||
            a.openbareruimte?.localeCompare(b.openbareruimte) ||
            huisnummerCompare ||
            a.huisletter?.localeCompare(b.huisletter) ||
            toevoegenCompare
        );
    }

    private sortWozSuggest(a: WozObject, b: WozObject) {
        let toevoegenCompare: number = a.huisnummertoevoeging?.localeCompare(b.huisnummertoevoeging);

        if (a.huisnummertoevoeging && b.huisnummertoevoeging) {
            const toevNumA: number = parseInt(a.huisnummertoevoeging, 10);
            const toevNumB: number = parseInt(b.huisnummertoevoeging, 10);
            if (!isNaN(toevNumA) && !isNaN(toevNumB)) {
                toevoegenCompare = toevNumA - toevNumB;
            }
        }

        return (
            a.woonplaatsnaam?.localeCompare(b.woonplaatsnaam) ||
            a.openbareruimtenaam?.localeCompare(b.openbareruimtenaam) ||
            (a.huisnummer && b.huisnummer && a.huisnummer - b.huisnummer) ||
            a.huisletter?.localeCompare(b.huisletter) ||
            toevoegenCompare
        );
    }
}
